import { defineStore } from "pinia";
import { Locality } from "~/types/localities";
import { ResponseType, Document, Media } from "~/types/general";

export const useLocalitiesStore = defineStore("localities", () => {
  // STATE
  const localities = ref<Locality[]>([]);
  const viewingLocality = ref<Locality>({} as Locality);
  const editingLocality = ref<Locality>({} as Locality);
  const file = ref<any>(null);

  // ACTIONS
  async function getLocalities() {
    try {
      const response = (await useVaniloApi(`/localities`)) as ResponseType<
        Locality[]
      >;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleLocality(id: number) {
    try {
      const response = (await useVaniloApi(
        `/localities/${id}`
      )) as ResponseType<Locality>;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createLocality(body) {
    try {
      const response = (await useVaniloApi(`/localities`, {
        method: "POST",
        body,
      })) as ResponseType<Locality>;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateLocality(id: number, body) {
    try {
      const response = (await useVaniloApi(`/localities/${id}`, {
        method: "POST",
        body,
      })) as ResponseType<Locality>;

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteLocality(id: number) {
    try {
      const response = await useVaniloApi(`/localities/${id}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function importLocalities() {
    let formData = new FormData();

    formData.append("file", file.value.file);

    try {
      const response = await useVaniloApi("/localities/import", {
        method: "POST",
        body: formData,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    // State
    localities,
    viewingLocality,
    editingLocality,
    file,

    // Actions
    getLocalities,
    getSingleLocality,
    createLocality,
    updateLocality,
    deleteLocality,
    importLocalities,
  };
});

<template>
  <div>Google Analytics</div>
</template>
<script setup lang="ts">
function saveGoogleAnalyticsSettings() {
  //   settingsStore.updateSettings({
  //     integrations: {
  //       pusher: settingsStore.editingSettings.integrations.pusher,
  //     },
  //   });
}

defineExpose({
  saveGoogleAnalyticsSettings,
});
</script>

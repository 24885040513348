export const useIsBookingPagesList = (route) => {
  return [
    useRoutesNames().booking,
    useRoutesNames().bookingType,
    useRoutesNames().bookingTypeName,
    useRoutesNames().bookingAccount,
    useRoutesNames().bookingAccountTab,
    useRoutesNames().bookingAccountLoginScreen,
    useRoutesNames().bookingOrderIdAgree,
    useRoutesNames().bookingOrderIdConfirmed,
    useRoutesNames().bookingOrderIdReview,
    useRoutesNames().bookingProviderId,
    useRoutesNames().bookingProviderIdServiceServiceid,
  ].includes(route.name);
}
import { defineStore } from "pinia";

import { OpenAiModel } from "~/types/ai";
import { Option, ResponseType } from "~/types/general";

export const useAiStore = defineStore("ai", () => {
  // State
  const openAiModels = ref<OpenAiModel[]>([]);

  // Getters
  const openAiModelsOptions = computed<Option[]>(() => {
    return openAiModels.value.map((model) => ({
      name: model.id,
      value: model.id,
    }));
  });

  // Actions
  async function getOpenAiModels(
    params?
  ): Promise<ResponseType<OpenAiModel[]>> {
    try {
      const response = await useVaniloApi("/open-ai/models", {
        params,
      });
      openAiModels.value = response.data;
      return response as ResponseType<OpenAiModel[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Expose state, getters, and actions
  return {
    openAiModels,
    openAiModelsOptions,
    getOpenAiModels,
  };
});

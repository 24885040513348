import { defineStore } from "pinia";

import { ResponseType, Option } from "~/types/general";
import { Tax } from "~/types/taxes";

export const useTaxesStore = defineStore("taxes", () => {
  // State
  const taxes = ref<Tax[]>([]);
  const addingTax = ref<Tax>({} as Tax);
  const editingTax = ref<Tax>({} as Tax);

  // Getters
  const taxesOptions = computed<Option[]>(() =>
    taxes.value.map((tax) => ({ name: tax.name, value: tax.id }))
  );

  // Actions

  // Fetch taxes
  async function getTaxes(): Promise<ResponseType<Tax[]>> {
    let response: any;

    try {
      response = await useVaniloApi("/taxes");
      taxes.value = response?.data;
    } catch (error) {
      console.log(error);
    }

    return response;
  }

  // Add tax
  async function addTax(): Promise<ResponseType<Tax[]>> {
    let response: any;

    try {
      response = await useVaniloApi("/taxes", {
        method: "POST",
        body: addingTax.value,
      });

      taxes.value.push(response?.data);
    } catch (error) {
      console.log(error);
    }

    return response;
  }

  // Update tax
  async function updateTax(): Promise<ResponseType<Tax[]>> {
    let response: any;

    try {
      response = await useVaniloApi(`/taxes/${editingTax.value.id}`, {
        method: "POST",
        body: {
          ...editingTax.value,
          id: undefined,
        },
      });

      taxes.value = taxes.value.map((tax) =>
        tax.id === editingTax.value.id ? response?.data : tax
      );
    } catch (error) {
      console.log(error);
    }

    return response;
  }

  // Delete tax
  async function deleteTax(id: number | string): Promise<ResponseType<Tax[]>> {
    let response: any;

    try {
      response = await useVaniloApi(`/taxes/${id || editingTax.value?.id}`, {
        method: "DELETE",
      });

      taxes.value = taxes.value.filter((item) => item.id !== +id);
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  return {
    // State
    taxes,
    addingTax,
    editingTax,

    // Getters
    taxesOptions,

    // Actions
    getTaxes,
    addTax,
    updateTax,
    deleteTax,
  };
});
